import { RelatedCategoriesWrapper } from './style';
import { H2, H3, H4, Row, Column } from '../../styleguide-remapped/styleguide-remapped';
import Link from 'next/link';

const RelatedCategories = ({ block, documentData, pageType, relatedCategories }) => {
  var headline = pageType == 'STARTPAGE' ? <H2 className={pageType}> {block.headline} </H2> : <H3 className={pageType}> {block.headline} </H3>;

  return (
    <RelatedCategoriesWrapper>
      {relatedCategories && <>{headline}</>}
      <Row outerSpace="none">
        {relatedCategories.map((category) => {
          return (
            <Column all={12} xs={12} s={6} m={3} px={0} key={category.id}>
              <Link className={'category'} href={'/' + category.attributes.slug}>
                <H4> {category.attributes.title} </H4>
              </Link>
            </Column>
          );
        })}
      </Row>
    </RelatedCategoriesWrapper>
  );
};

export default RelatedCategories;
