import { Button } from '../../styleguide-remapped/styleguide-remapped';
import { ChipWrapper } from './style';
import React, { useState, useEffect } from 'react';
import { getCookie } from 'cookies-next';

const Chip = ({ title, link }) => {
  const _lightThemeParam = 'light';
  const [theme, setTheme] = useState(_lightThemeParam);

  useEffect(() => {
    if (getCookie('theme') !== _lightThemeParam) {
      const themeCookie = getCookie('theme');
      if (themeCookie) {
        setTheme(themeCookie);
      } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    }
  });

  return (
    <ChipWrapper className={`chip ${theme}-chip`}>
      <Button type="chip" text={title} href={link}></Button>
    </ChipWrapper>
  );
};

export default Chip;
