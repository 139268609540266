import styled from 'styled-components';

export const RelatedCategoriesWrapper = styled.div.withConfig({
  displayName: 'RelatedCategoriesWrapper',
  componentId: 'related-categories-wrapper',
})([
  `
    margin-bottom: 32px;

    h2, > h2 {
        width: 100%;
        font-style: normal;
        font-weight: 530;
        line-height: 120%;
        text-align: center !important;
        padding-bottom: 16px;
        font-size: 36px;
    }

    h3 {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-align: center;
        padding-bottom: 16px;
        font-size: 27px;
    }

    > div {
        justify-content: center;
    }

    .category {
        height: 150px;
        background: rgb(4, 68, 155);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #05489E 0%, #05489E 0.01%, #003480 51.04%, #002760 100%);
        
        h4 {
            color: #FFF;
            font-style: normal;
            font-weight: 530;
            line-height: 120%;
            transition: font-size .1s;
            text-align: center;
            padding: 0 10px;
            min-width: 300px;
        }
    }

    .category:hover > h4 {
        font-size: 30px;
    }

    @media screen and (max-width: 481px) {
        .category:hover > h4 {
            font-size: 27px;
        }
    }

    .row {
        justify-content: center;
    }
`,
]);
