import styled from 'styled-components';

const ImageGalleryWrapper = styled.div.withConfig({
	displayName: 'ImageGalleryWrapper',
	componentId: 'image-gallery-wrapper'
})([`
    > div {
        padding: 0;
    }

    .image-gallery-overlay .gallery-pagination-container {
        padding: 0.6em 32px;
    }

    .image-gallery-overlay .gallery-description-container {
        padding: 0.9em 32px;
    }
`]);

export default ImageGalleryWrapper;