import { RelatedTopicsWrapper } from './style';
import { H3, H5, Row, Column } from '../../styleguide-remapped/styleguide-remapped';
import { mapTextToIcon } from '../../custom/topic-icons';
import { uid } from 'react-uid';
import Link from 'next/link';

const RelatedTopics = ({ block, documentData, pageType, relatedCategories, relatedTopics }) => {
  return (
    <RelatedTopicsWrapper className={pageType}>
      {documentData.attributes?.topics?.data && <H3> {block.headline} </H3>}
      <Row className="row">
        {documentData.attributes?.topics?.data?.map((topic) => {
          return (
            <Column all={12} xs={6} s={4} m={4} px={0} mx={0} key={uid(topic)}>
              <Link href={`/${documentData.attributes.slug}/${topic.attributes.slug}/`}>
                <Row className="topic-container">
                  <div className="icon-container">
                    <div className="svg-container">{mapTextToIcon(topic.attributes.icon)}</div>
                  </div>
                  <H5> {topic.attributes.title} </H5>
                </Row>
              </Link>
            </Column>
          );
        })}
      </Row>
    </RelatedTopicsWrapper>
  );
};

export default RelatedTopics;
