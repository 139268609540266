import { ParagraphWrapper } from '../paragraph/style';
import { VideoWrapper } from './style';
import { getStrapiURL } from '../../../lib/api';

const Video = ({ block, documentData, pageType }) => {
  return (
    <VideoWrapper>
      {(block.media?.data?.attributes?.url || block.video?.data?.attributes?.url) && (
        <video width="100%" controls>
          <source src={getStrapiURL(block.media?.data?.attributes?.url ?? block.video?.data?.attributes?.url)}></source>
        </video>
      )}
      {(block.description || block.videoDescription) && (
        <ParagraphWrapper>
          <p className="small-text">{block.description ?? block.videoDescription}</p>
        </ParagraphWrapper>
      )}
    </VideoWrapper>
  );
};

export default Video;
