export function isExternalUrl(url) {
    const isExternalUrl =
        (url && !url.startsWith('/') && !RegExp(APP_SETTINGS.appUris.join('|'))
            .exec(url));

    return isExternalUrl;
}

export function switchToWebPImages(content) {
    if (content) {
        content = JSON.parse(JSON.stringify(content).replaceAll('.png', '.webp').replaceAll('.jpg', '.webp').replaceAll('.jpeg', '.webp'));
    }

    return content;
}

export function transformLink(href, isInternalLink) {
    const isPdf = href.endsWith('.pdf');
    href = href.replace(/index\.html#/, '');
 
    if (isInternalLink && !isPdf) {
        if (href.startsWith('\/mailto')) {
            href = href.replace('\/mailto', 'mailto');
        }

        return href;
    }

    if(!isInternalLink && !href.startsWith('http')){
        href = `http://${href}`;
    }

    return href;
}