import React from 'react';
import { ReactSVG } from 'react-svg';

const iconsContext = require.context('../../assets/logos/topic', false, /\.svg$/);

// Get all icon filenames from the context
const iconFilenames = iconsContext.keys();

// Map icon filenames to corresponding components
const iconsMap = iconFilenames.reduce((map, filename) => {
  const iconName = filename.replace('./', '').replace('.svg', '');
  const IconComponent = () => <ReactSVG src={iconsContext(filename).default} />;
  map[iconName] = IconComponent;
  return map;
}, {});

export const mapTextToIcon = (iconText) => {
  var IconComponent = iconsMap[iconText];

  if (!IconComponent) {
    IconComponent = iconsMap['settings'];
  }

  return <IconComponent />;
};
