import React from 'react';
import settings from '../../settings.json';
import Collapse from './collapse';
import ContactCta from './contact-cta';
import Headline from './headline';
import Image from './image';
import Paragraph from './paragraph';
import RelatedArticles1Column from './related-articles-1-column';
import RelatedArticles2Columns from './related-articles-2-columns';
import RelatedCategories from './related-categories';
import RelatedTopics from './related-topics';
import Searchbar from './searchbar';
import Tablist from './tablist';
import Video from './video';
import Sections from './sections';
import ImageGallery from './gallery';
import { uid } from 'react-uid';

export const ComponentMappings = {
  //existing components in styleguide
  'block.tablist': Tablist,
  'block.video': Video,
  'block.collapse': Collapse,
  'block.headline': Headline,
  'block.image': Image,
  'block.paragraph': Paragraph,
  'block.contact-cta': ContactCta,
  'block.related-articles-1-column': RelatedArticles1Column,
  'block.related-articles-2-columns': RelatedArticles2Columns,
  'block.related-categories': RelatedCategories,
  'block.related-topics': RelatedTopics,
  'block.searchbar': Searchbar,
  'block.sections': Sections,
  'block.gallery': ImageGallery,
};

const Block = (block = { __component, id, mappingIndex }, documentData, pageType, relatedCategories, relatedTopics) => {
  if (typeof ComponentMappings[block.__component] !== 'undefined') {
    return React.createElement(ComponentMappings[block.__component], {
      key: block.mappingIndex,
      block: block,
      documentData: documentData,
      pageType: pageType,
      relatedCategories: relatedCategories ?? [],
      relatedTopics: relatedTopics ?? [],
    });
  }

  if (settings.showMissingComponents) {
    return React.createElement(
      () => (
        <p key={uid(block)}>
          The component for pageType {pageType} needs to be created: <strong>{block.__component}</strong>
          <br />
          {JSON.stringify(block)}
        </p>
      ),
      { key: block.mappingIndex }
    );
  } else {
    return null;
  }
};

export default Block;
