import DOMPurify from 'isomorphic-dompurify';
import { isExternalUrl, transformLink } from '../../scripts/utils/linkHelper';

export default function sanitize(content) {
    content = content
        .replace(/<p[^>]*>/g, '')
        .replace(/<\/p>/g, '<br /><br />')
        .replace(/^(\s*<br\s*\/?\s*>\s*)*|(\s*<br\s*\/?\s*>\s*)*\s*$/g, "$2");

    const defaultSanitizeOptions = {
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'br', 'h2', 'h3', 'h4', 'h5', 'img', 'figure'],
        ALLOWED_ATTR: ['href', 'target', 'src', 'alt', 'srcset', 'sizes', 'width', 'title', 'class']
    };

    DOMPurify.addHook('uponSanitizeElement', (
        currentNode,
        hookEvent
    ) => {
        if (hookEvent.tagName === 'a') {
            if (currentNode.hasAttribute('xlink:href') || currentNode.hasAttribute('href')) {
                const href = currentNode.getAttribute('href') || currentNode.getAttribute('xlink:href');
                const isExternal = isExternalUrl(href);

                if (isExternal) {
                    currentNode.setAttribute('target', '_blank');
                    currentNode.setAttribute('href', transformLink(href, false));
                } else {
                    currentNode.setAttribute('target', '_self');
                    currentNode.setAttribute('href', transformLink(href, true));
                }
            }
        }
        return currentNode;
    });

    const sanitize = (dirty, options) => ({
        __html: DOMPurify.sanitize(
            dirty,
            { ...defaultSanitizeOptions, ...options }
        )
    });

    return sanitize(content ?? '', {});
}
