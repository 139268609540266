import styled from 'styled-components';

export const VideoWrapper = styled.div.withConfig({
  displayName: 'VideoWrapper',
  componentId: 'video-wrapper',
})([
  `
    margin-bottom: 32px;
    width: 100%;
    position: relative;

    > video {
      height: calc((100vw - 57px) * 9 / 16) !important;
    }

    @media screen and (min-width: 961px) {
      > video {
        height: calc((100vw - 65px) * 9 / 16) !important;
      }
    }

    @media screen and (min-width: 1185px) {
      > video {
        height: calc(1120px * 9 / 16) !important;
      }
    }

    @media screen and (min-width: 1281px) {
      > video {
        height: calc((836px * 9) / 16) !important;
      }
    }
    
    p {
        margin: 8px 0;
        letter-spacing: 0.6px;
    }
`,
]);
