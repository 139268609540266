import styled from 'styled-components';

export const RelatedArticles2ColumnWrapper = styled.div.withConfig({
  displayName: 'RelatedArticles2ColumnWrapper',
  componentId: 'related-articles-2-column-wrapper',
})([
  `
    margin-bottom: 64px;
    
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 120%;
        text-align: center;
        margin-bottom: 8px;
    }

    h5 {
        font-style: normal;
        font-weight: 530;
        font-size: 20px;
        line-height: 120%;
        
        margin: 0;
        margin-right: 16px;
        white-space: normal;
        text-wrap: wrap;
    }

    .column {
        padding: 0;
        @media screen and (min-width: 1281px) {
            :nth-of-type(1) {
                padding-right: 16px;
            }
    
            :nth-of-type(2) {
                padding-left: 16px;
            }
        }
    }

    .article-container:hover > .article-header > img {
        visibility: visible;
    }

    .article-container:hover > .article-header > h5 {
        color: #2D91FF;
    }

    .article-container > p {
        font-size: inherit;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.3px;
        padding: 0;
        white-space: normal;

        margin-right: 32px;
    }

    .article-header {
        justify-content: space-between;
        align-items: top;
        flex-wrap: nowrap;
    }

    img {
        display: inherit;
        visibility: hidden;
        margin-top: 8px;
    }
`,
]);

export const ArrowRightIcon = styled.img.withConfig({
  displayName: 'ArrowRightIcon',
  componentId: 'arrow-right-icon',
})([
  `
    width: 15px;
    height: 10px;
    display: none;
`,
]);
