import styled from 'styled-components';

export const ContactCtaWrapper = styled.div.withConfig({
  displayName: 'ContactCtaWrapper',
  componentId: 'contact-cta-wrapper',
})([
  `
    margin-bottom: 32px;
    display: flex;  
    align-items: center;
    flex-direction: column;

    :is(.CATEGORY), :is(.ARTICLE) {
        margin-bottom: 64px;
    }

    :is(.ARTICLE) {
        padding-top: 32px;
    }

    h4 {
        font-style: normal;
        font-weight: 530;
        line-height: 120%;
        margin-bottom: 16px;
        text-align: center;
    }

    a {
        font-style: normal;
        font-weight: 520;
        font-size: 16px;
        line-height: 130%;
    }

    @media (hover: hover) {
        a:hover {
            background: #2d91ff !important;
        }
    }

    span {
        display: flex;
        align-items: center;
    }

    :is(.STARTPAGE) {
        margin-top: 64px;
    }
`,
]);

export const ContactIcon = styled.div.withConfig({
  displayName: 'ContactIcon',
  componentId: 'contact-icon',
})([
  `
        margin-bottom: 16px;
        width: 85px;
        height: auto;
`,
]);
