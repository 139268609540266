import styled from 'styled-components';

export const HeadlineWrapper = styled.div.withConfig({
  displayName: 'HeadlineWrapper',
  componentId: 'headline-wrapper',
})([
  `
    > h2 {
        font-style: normal;
        font-weight: 530;
        font-size: 24px;
        line-height: 120%;
        padding-left: 0;
        white-space: normal;
        text-wrap: wrap;

        padding-bottom: 0px;
        margin-bottom: 24px;
    }

    .article-main-headline {
        font-size: 36px;
    }
`,
]);
