import React, { useState } from 'react';
import { P, Row, Column } from '../../styleguide-remapped/styleguide-remapped';
import { CollapseWrapper, MediaWrapper } from './style';
import sanitize from '../../../scripts/utils/sanitize';
import { uid } from 'react-uid';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import ArrowDownSvg from '../../../assets/logos/arrowDown';
import Image from '../image';
import Video from '../video';
const CL = dynamic(() => import('@ard-online/component-styleguide/dist/components/Collapse/Collapse'), { ssr: false });

const Collapse = ({ block, documentData, pageType }) => {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const isFaq = documentData?.attributes?.isFaq && block.id === documentData.attributes.blockContent.find((d) => d.__component === 'block.collapse')?.id ? true : false;

  const getFaqStructuredData = () => {
    let isFirstElementToRender = true;
    const result = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [],
    };

    documentData.attributes.blockContent
      .filter((d) => d.__component === 'block.collapse')
      .forEach((contentBlock) => {
        contentBlock.tab?.forEach((tab) => {
          const faqJson = {
            '@type': 'Question',
            name: tab.title,
            acceptedAnswer: {
              '@type': 'Answer',
              text: sanitize(tab.custom).__html,
            },
          };
          result.mainEntity.push(faqJson);
        });
      });

    return result;
  };

  const collapseItems = block.tab?.map((tab, index) => ({
    button: {
      text: tab.title,
      type: 'square',
      id: uid(tab),
      iconRight: <ArrowDownSvg className={activeIndexes.includes(index) ? 'rotate' : ''} />,
      onClick: (e) => {
        setActiveIndexes(!activeIndexes.includes(index) ? [index, ...activeIndexes] : activeIndexes.filter((i) => i != index));
      },
    },
    content: tab.custom ? (
      <>
        <Row>
          <Column>
            <P dangerouslySetInnerHTML={sanitize(tab.custom)}></P>
          </Column>
        </Row>
        {tab.image?.data && (
          <MediaWrapper>
            <Image block={tab}></Image>
          </MediaWrapper>
        )}
        {tab.video?.data && (
          <MediaWrapper>
            <Video block={tab}></Video>
          </MediaWrapper>
        )}
      </>
    ) : null,
  }));

  return (
    <>
      {isFaq && (
        <Head>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(getFaqStructuredData()) }} />
        </Head>
      )}
      <CollapseWrapper className="primary-link paragraph">
        <CL items={collapseItems} initialElements={[]}></CL>
      </CollapseWrapper>
    </>
  );
};

export default Collapse;
