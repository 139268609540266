import styled from 'styled-components';

export const TablistWrapper = styled.div.withConfig({
  displayName: 'TablistWrapper',
  componentId: 'tablist-wrapper',
})`
    margin-bottom: 32px;
    position: relative;
    overflow-x: hidden;

    nav {
        margin-bottom: 16px;

        > div > div > div:last-child > span {
            background: #2D91FF;
        }
    }

    ul, ol {
        margin-top: 0px;
    }

    button {
        font-size: inherit;

        &:hover, &:hover.active {
            background: #2D91FF !important;
                > span {
                    color: #FFF;
                }   
            }
        }

        span {
            color: ${(props) => props.theme.text};
            font-weight: 520;
        }
    }

    p {
        font-size: inherit;
    }
`;
