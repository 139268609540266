import React from 'react';

const ContactSvg = () => (
    <svg width="86" height="63" viewBox="0 0 86 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.6758 13.6123H84.2598C84.6268 13.6123 84.9787 13.7581 85.2383 14.0176C85.4978 14.2771 85.6436 14.6291 85.6436 14.9961V50.9746C85.6436 51.3416 85.4978 51.6936 85.2383 51.9531C84.9787 52.2126 84.6268 52.3584 84.2598 52.3584H77.3408L70.3942 61.6575C70.2455 61.8404 70.0542 61.9841 69.8371 62.0757C69.6199 62.1674 69.3836 62.2044 69.1488 62.1833C68.9147 62.1676 68.689 62.0902 68.4945 61.9589C68.3001 61.8277 68.1439 61.6473 68.0417 61.4361L63.5029 52.3584H31.6758C31.3087 52.3584 30.9568 52.2126 30.6973 51.9531C30.4377 51.6936 30.292 51.3416 30.292 50.9746V14.9961C30.292 14.6291 30.4377 14.2771 30.6973 14.0176C30.9568 13.7581 31.3087 13.6123 31.6758 13.6123ZM33.0595 49.5908H63.5029C64.0169 49.595 64.5196 49.7421 64.9546 50.0159C65.3897 50.2896 65.7399 50.6791 65.9661 51.1407L69.5086 58.198L75.0437 50.6979C75.3015 50.3541 75.6358 50.0752 76.0201 49.883C76.4044 49.6909 76.8281 49.5908 77.2578 49.5908H82.876V16.3799H33.0595V49.5908Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M55.708 10.9007V1.38379C55.708 1.01679 55.5623 0.664813 55.3027 0.405302C55.0432 0.145791 54.6913 0 54.3242 0H1.74024C1.37323 0 1.02126 0.145791 0.761747 0.405302C0.502236 0.664813 0.356445 1.01679 0.356445 1.38379V37.3623C0.356445 37.7293 0.502236 38.0813 0.761747 38.3408C1.02126 38.6003 1.37323 38.7461 1.74024 38.7461H8.65918L15.6058 48.0452C15.7545 48.2281 15.9458 48.3717 16.1629 48.4634C16.3801 48.5551 16.6164 48.5921 16.8512 48.571C17.0853 48.5553 17.311 48.4779 17.5055 48.3466C17.6999 48.2154 17.8561 48.035 17.9583 47.8238L22.4971 38.7461H27.5808V35.9785H22.4971C21.9831 35.9827 21.4804 36.1298 21.0454 36.4036C20.6103 36.6773 20.2601 37.0668 20.0339 37.5284L16.4914 44.5857L10.9563 37.0856C10.6985 36.7418 10.3642 36.4629 9.97991 36.2707C9.59562 36.0786 9.17187 35.9785 8.74221 35.9785H3.12403V2.76758H52.9405V10.9007H55.708Z"/>
    </svg>
);

export default ContactSvg;
