import styled from 'styled-components';

export const ImageWrapper = styled.div.withConfig({
  displayName: 'ImageWrapper',
  componentId: 'image-wrapper',
})([
  `
    margin-bottom: 32px;

    > img {
        width: 100%;
        height: auto;
    }

    > p {
        margin: 8px 0;
        letter-spacing: 0.6px;
    }
`,
]);
