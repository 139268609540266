import styled from 'styled-components';

export const RelatedTopicsWrapper = styled.div.withConfig({
  displayName: 'RelatedTopicsWrapper',
  componentId: 'related-topics-wrapper',
})`
  margin-bottom: 32px;

  :is(.CATEGORY) {
    margin-bottom: 64px;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 16px;
  }

  a {
    height: 94px;
    margin-top: 18px;
  }

  .topic-container {
    max-height: 94px;

    background: ${(props) => props.theme.relatedTopics.background};
    border-radius: 4px;
    margin: 0;

    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;

    position: relative;
    top: 0;
    transition: top ease 0.1s;

    h5 {
      margin: auto 24px;

      font-style: normal;
      font-weight: 520;
      font-size: 20px;
      line-height: 120%;

      flex: 1;
    }
  }

  .topic-container:hover {
    background: #2d91ff;
    > h5 {
      color: #fff;
    }
  }

  .icon-container {
    width: 94px;
    height: 94px;

    fill: white;
    background: #0071ee;
    border-radius: 4px 0 0 4px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row {
    justify-content: center;
    margin: 0;
  }
`;
