import { getStrapiMedia } from '../../../lib/media';
import NextImage from 'next/image';
import { ImageWrapper } from './style';
import { uid } from 'react-uid';

const Image = ({ block, documentData, pageType }) => {
  if (!block.media?.data?.attributes?.url && !block.image?.data?.attributes?.url) {
    return null;
  }

  const { alternativeText, width, height } = block.media?.data?.attributes ?? block.image?.data?.attributes;

  return (
    <ImageWrapper>
      <NextImage key={uid(block)} width={width || '100%'} height={height || '100%'} src={getStrapiMedia(block.media ?? block.image)} alt={alternativeText || ''} />
      {(block.description || block.imageDescription) && <p className="small-text">{block.description ?? block.imageDescription}</p>}
    </ImageWrapper>
  );
};

export default Image;
