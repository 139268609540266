import styled from 'styled-components';

export const SearchbarWrapper = styled.div.withConfig({
  displayName: 'SearchbarWrapper',
  componentId: 'searchbar-wrapper',
})([
  `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;

    h1 {
        font-style: normal;
        font-weight: 530;
        font-size: 40px;
        line-height: 120%;
        text-align: center;
        padding-bottom: 24px;
    }

    button > svg {
      background-color: rgba(0,0,0,0.25);
    }

    svg {
      fill: #000;
    }

    form {
        max-width: 750px;
        width: 100%;

        input::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: center;
       }

       > p {
        color: #fff;
        display: inline-block;
        width: 100%;
        max-width: 750px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.3px; 
        text-align: center;
        position: relative;
        margin: 8px 0;
        > span {
          font-weight: 530;
        }
       }
    }
`,
]);
