import React from 'react';
import { getStrapiMedia } from '../../../lib/media';
import ImageGalleryWrapper from './styles.js';
import { Gallery, H2 } from '../../styleguide-remapped/styleguide-remapped.js';

const ImageGallery = ({ block, documentData, pageType }) => {
	const galleryData = {
		id: `${block.id}_gallery`,
		headline: block.headline,
		hasAutoplay: block.hasAutoplay,
		hasFullscreen: block.hasFullscreen,
		hasLoop: block.hasLoop,
		isBulletsPagination: block.isBulletsPagination,
		items: (block?.images ?? []).map(d => {
			return {
				description: d.description,
				image: {
					src: getStrapiMedia(d.image),
					alt: d.image?.data?.attributes?.alternativeText ?? '',
					title: d.image?.data?.attributes?.caption ?? ''
				}
			}
		})
	};
	
	return (
		<ImageGalleryWrapper>
			<H2>{block.headline}</H2>
			<Gallery {...galleryData} />
		</ImageGalleryWrapper>
	);
};

export default ImageGallery;