import styled from 'styled-components';

export const CollapseWrapper = styled.div.withConfig({
  displayName: 'CollapseWrapper',
  componentId: 'collapse-wrapper',
})`
  margin-bottom: 32px;

  > section > ul li > button.active {
    background: transparent !important;
    color: inherit !important;
  }

  > section > ul li > button:hover,
  > section > ul li > button.active:hover,
  > section > ul li > button:hover ~ div {
    background: #3e4c69 !important;
    color: #fff !important;

    > div > div > svg > path {
      fill: #fff;
    }

    p {
      color: #fff !important;
    }
  }

  > section > ul > li > button {
    min-height: 58px;

    > span {
      margin: 16px;
      margin-top: 20px;
      margin-left: 0;

      height: auto;
      white-space: normal;
      text-wrap: balance;
      margin-right: 16px;
      word-break: break-word;
      line-height: inherit;
    }
  }

  ul > li {
    list-style-type: disc;

    > ul > li {
      list-style-type: circle;

      > ul > li {
        list-style-type: square;
      }
    }
  }

  button {
    padding: 0 16px;
    font-size: inherit;

    span {
      padding: 0;

      font-size: inherit !important;
      font-weight: 530;
    }
  }

  button + div {
    font-size: inherit;
    > div {
      margin: 0;

      &:last-child > div {
        padding-bottom: 16px;
      }

      > div {
        padding: 0 16px 32px;
        padding-top: 0;
        margin-bottom: 0;

        > p {
          color: ${(props) => props.theme.text};
          font-size: inherit;
          padding-bottom: 0;
        }
      }

      > div > div {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  svg {
    transition: transform 0.3s ease-in-out;

    &.rotate {
      transform: rotate(180deg);
    }

    > path {
      fill: ${(props) => props.theme.text};
    }
  }
`;

export const MediaWrapper = styled.div.withConfig({
  displayName: 'MediaWrapper',
  componentId: 'media-wrapper',
})`
  p {
    margin-bottom: 0;
  }

  > div {
    margin-bottom: 0;
  }
`;
