import { H2 } from '../../styleguide-remapped/styleguide-remapped';
import { HeadlineWrapper } from './style';

const Headline = ({ block, documentData, pageType }) => {
  return (
    <HeadlineWrapper>
      <H2>{block.title}</H2>
    </HeadlineWrapper>
  );
};

export default Headline;
