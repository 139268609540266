import { TablistWrapper } from './style';
import { P } from '../../styleguide-remapped/styleguide-remapped';
import sanitize from '../../../scripts/utils/sanitize';
import dynamic from 'next/dynamic';
import Image from '../image';
import Video from '../video';
const TL = dynamic(() => import('@ard-online/component-styleguide/dist/components/Tablist/Tablist'), { ssr: false });

const Tablist = ({ block, documentData, pageType }) => {
  if (!(block.tab?.length > 0)) {
    return null;
  }

  const tabArray = block.tab.map((tab) => ({
    title: tab.title,
    content: (
      <>
        <P dangerouslySetInnerHTML={sanitize(tab.custom)}></P>
        {tab.image?.data && <Image block={tab}></Image>}
        {tab.video?.data && <Video block={tab}></Video>}
      </>
    ),
  }));

  return (
    <TablistWrapper className="primary-link paragraph">
      <TL initialTab={0} navbarAlignment="left" tabs={tabArray} />
    </TablistWrapper>
  );
};

export default Tablist;
