import { default as Chip } from '../chip';
import { BreadcrumbWrapper } from './style';
import { uid } from 'react-uid';
import React from 'react';

const Breadcrumbs = ({ categories, topics = null, articles, breadcrumbKey }) => {
  categories = categories.sort((a, b) => (a.attributes.rank > b.attributes.rank ? 1 : -1)); //might have filled up using placeholders -> we need to sort again
  const articleIds = [...new Set(articles.map((article) => article.id))];
  const topicIds = [];
  const categoryIds = [];

  topics?.forEach((topic) => {
    topic.attributes.articles?.forEach((articleSection) => {
      if (!topicIds.includes(topic.id) && articleSection.articles?.data?.find((d) => articleIds.indexOf(d.id) > -1)) {
        topicIds.push(topic.id);
      }
    });
  });

  categories.forEach((category) => {
    category?.attributes?.topics?.data?.forEach((categoryTopic) => {
      if (!categoryIds.includes(category.id) && (topics == null || topicIds.includes(categoryTopic.id))) {
        categoryIds.push(category.id);
      }
    });
  });

  //console.log('articles', articles, articleIds);
  //console.log('topics', topics, topicIds);
  //console.log('categories', categories, categoryIds);

  return (
    <BreadcrumbWrapper>
      {categories.map((category, index) => {
        if (categoryIds.includes(category?.id)) {
          return <Chip title={category.attributes.title} link={`/${category.attributes.slug}/`} key={`breadcrumb-category-${breadcrumbKey}-${uid(category.id)}-${index}`}></Chip>;
        }
      })}
      {categories.map((category, categoryIndex) => (
        <React.Fragment key={`breadcrumb-topic-wrapper-${breadcrumbKey}-${uid(category ?? categoryIndex)}-${categoryIndex}`}>
          {categoryIds.includes(category?.id) &&
            category.attributes.topics?.data?.map((categoryTopic, categoryTopicIndex) => {
              if (topicIds.includes(categoryTopic.id)) {
                return <Chip title={categoryTopic.attributes.title} link={`/${category.attributes.slug}/${categoryTopic.attributes.slug}/`} key={`breadcrumb-topic-${breadcrumbKey}-${uid(category)}-${uid(categoryTopic)}-${categoryIndex}-${categoryTopicIndex}`}></Chip>;
              }
            })}
        </React.Fragment>
      ))}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumbs;
