import React, { useState, useEffect } from 'react';
import { SearchbarWrapper } from './style';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { fetchSearchAPI, removeSavedUserSearchResultSelection, saveUserSearchResultSelection } from '../../../lib/api';
import { P } from '../../styleguide-remapped/styleguide-remapped';
import { trackSearchResultClick } from '../../../scripts/tracking/tracking';

const SearchInput = dynamic(() => import('@ard-online/component-styleguide/dist/components/SearchInput/SearchInput'), { ssr: false });

const Searchbar = ({ block, documentData, pageType, totalResults = null, onSubmitCallback = (searchedWord) => { }, afterRedirectCallback = () => { }, searchWord = null, children = null }) => {
  const router = useRouter();
  const [searchedWord, setSearch] = useState(searchWord ?? '');
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  useEffect(() => {
    async function searchWithMeili() {
      const searchResult = await fetchSearchAPI(
        searchedWord,
        (e, hit, data) => {
          if (e === 'cleared') {
            setSearchHasFocus(false);
            removeSavedUserSearchResultSelection(hit);
            searchWithMeili();
          } else {
            saveUserSearchResultSelection(hit);
            if (data?.query) {
              trackSearchResultClick(window, data.query, data.estimatedTotalHits ?? 0, hit.id, (data.hits.reverse().findIndex((d) => d.id === hit.id) ?? -1) + 1, true);
            }
            window.location.href = `/artikel/${hit.slug}/`;
          }
        },
        7,
        false,
        window
      );
      setSearchSuggestions(searchResult);
    }
    searchWithMeili();
  }, [searchedWord]);

  return (
    <>
      <SearchbarWrapper className={pageType}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchHasFocus(false);
            onSubmitCallback(searchedWord);
            router.push(`/suche/${encodeURIComponent(searchedWord)}/`).then(() => {
              afterRedirectCallback();
            });
          }}
        >
          <SearchInput
            label="Wie können wir helfen?"
            hasFocus={searchHasFocus}
            initialValue={searchedWord}
            onChange={(e) => {
              setSearchHasFocus(true);
              setSearch(e);
            }}
            searchSuggestions={searchSuggestions}
          />
        </form>
      </SearchbarWrapper>
      {children}
    </>


  );
};

export default Searchbar;
