import styled from 'styled-components';

export const RelatedArticles1ColumnWrapper = styled.div.withConfig({
  displayName: 'RelatedArticles1ColumnWrapper',
  componentId: 'related-articles-1-column-wrapper',
})([
  `
    margin-bottom: 32px;

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 120%;
        color: #FFFFFF;
        margin: auto;
        margin-bottom: 24px;
    }

    a:hover {
        h5 {
            color: #2D91FF;
        }

        img {
            visibility: visible;
        }
    }

    h5 {
        font-style: normal;
        font-weight: 530;
        font-size: 20px;
        line-height: 120%;
        margin: 0;
        margin-right: 16px;
        white-space: normal;
        text-wrap: wrap;
    }

    a > p {
        margin-block-start: 0.5em !important;
        font-size: inherit;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.3px;
        padding: 0;
        margin-right: 32px;
    }

    img {
        display: inherit;
        visibility: hidden;
        margin-top: 8px;
    }

    .article-header {
        justify-content: space-between;
        align-items: top;
        flex-wrap: nowrap;
    }
`,
]);
