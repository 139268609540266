import styled from 'styled-components';

export const ChipWrapper = styled.div.withConfig({
  displayName: 'ChipWrapper',
  componentId: 'chip-wrapper',
})([
  `
    margin-right: 8px;
    margin-bottom: 8px;

    :last-child {
        margin-right: 0;
    }
    
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;

        background: #243350;
        border-radius: 9999px;
    }

    > a {
      box-shadow: none !important;
    }

    @media (hover: hover) {
      a {
        &:hover {
          background-color: #2D91FF !important;
          box-shadow: none !important;
          color: #FFF;
        }
      }
    }

    &.dark-chip {
      a {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &.light-chip {
      > a {
        background-color: rgba(0,14,41, 0.2);
        color: rgba(0,14,41) !important;
      }
    }

    span {
        font-style: normal;
        font-weight: 520;
        font-size: 14px;
        line-height: 120%;
        height: min-content;
        padding: 3.5px 0;
    }
`,
]);
