import { ContactCtaWrapper, ContactIcon } from './style';
import { H4, Button } from '../../styleguide-remapped/styleguide-remapped';
import ContactSvg from '../../../assets/logos/contactIcon';

const ContactCta = ({ block = { description }, documentData, pageType }) => {
  return (
    <ContactCtaWrapper id={`${block.__component}-${block.id}`} className={pageType}>
      <ContactIcon className="theme-icon">
        <ContactSvg />
      </ContactIcon>
      <H4> {block.description} </H4>
      <Button type="primary" text="Kontakt aufnehmen" href={block.link} />
    </ContactCtaWrapper>
  );
};

export default ContactCta;
