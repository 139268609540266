import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div.withConfig({
  displayName: 'BreadcrumbWrapper',
  componentId: 'breadcrumb-wrapper',
})([
  `
    margin-top: 4px;
    margin-bottom: 8px;

    > div {
        display: inline-block;

        @media (hover: hover) {
          a:hover {
            color: #FFF !important;
          }
        }
    }
`,
]);
